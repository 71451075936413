@import url('https://fonts.googleapis.com/css2?family=Ubuntu:wght@700&display=swap');

*, *::before, *::after{
    box-sizing: border-box;
}

.custom-field{
    position: relative;
    font-size: 14px;
    padding-top: 20px;
    margin-top: 5px;
}


.custom-field input{
    padding: 12px;
    width: 300px;
    font-size: 14px;
    border-radius: 3px;
    border: 1.5px solid gray;
    outline-color: deepskyblue;
}

.custom-field .placeholder{
    position: absolute;
    left: 12px;
    top: calc(50% + 10px);
    transform: translateY(-50%);
    color: #aaa;
    transition: top 0.3s ease, font-size 0.3s ease, color 0.3s ease;
}

.custom-field input:valid + .placeholder,
.custom-field input:focus + .placeholder{
    top: 21px;
    font-size: 10px;
    color: #222;
    padding: 5px;
    color: #EBEBFF;
    background-color: #222;
}

.message{
    font-size: 70px;
    font-family: 'Ubuntu', sans-serif;
    color: steelblue;
}

.success{
    font-size: 18px;
    margin-top: 15px;
    color: seagreen;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.forgot-pass{
  display:flex;
  justify-content:center;
  margin:10px;
}