.orderCard{
    width:30%;
    height: 7rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 10px;
    z-index: 2;
    border-radius: 5px;
}
.orderCard-heading{
    display: flex;
    justify-content: flex-start;
    margin: 25px 25px 0px 35px;
    font-size: 22px;
    font-weight: bolder;
}
.orderCard-details{
    display: flex;
    justify-content: flex-start;
    margin: 25px 0px 20px 35px;
}
@media only screen and (max-width: 800px) {
    .orderCard{
        font-size: 12px;
        width:25%;
        height: max-content;
        margin: 0% 0% 0% 3%;
    }
    .orderCard-heading{
        display: flex;
        justify-content: flex-start;
        margin: 2% 2% 0px 3%;
        font-size: 12px;
    }
}