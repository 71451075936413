body {
  position: relative;
  font-family: "Poppins", sans-serif;
  overflow-x:hidden;
  background-color: #EBEBFF;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
button{
  cursor: pointer ;
  border-radius: 3px;
  font-size: 16px;
  padding: 12px;

}
button:hover{
  cursor: pointer;
}
button:active{
  opacity: 0.7;
}