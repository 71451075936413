.orderList{
    margin: 0% 0% 1% 18%;
    display: flex;
    justify-content: center;
    color: black;
    width:80%;
    font-size: medium;
    
}

.orderList-options{
    display: flex;
    list-style: none;
    justify-content: space-evenly;
    
}
.orderList-options-option{
    width: 190px;
    /* font-size: 20px; */
    margin: 1%;
    cursor: pointer;
    background: lightblue;
    padding: 5px 5px 5px 25px;
    border-radius: 5px;
    

}
@media only screen and (max-width: 800px) {
    .orderList{
        width: 80%;
        margin: 0% 0% 1% 3%;
    }
    .orderList-options-option{
        width: 30%;
    }
}