.orderTable{
    margin: 0% 0% 5% 20%;
    color: black;
    width:80%;
    line-height: 40px;
    border-collapse: collapse;
    text-align: center;
}
th{
    width: 180px;
}
th,td{
    border: 1px solid rgb(80, 80, 80);
    padding: 8px;
}
.orderTable-button:hover{
    cursor: pointer;
}
.verified{
    font-family:cursive;
    color:#3ACF53;
    font-weight:600;
    /* font-size:1.2em;  */
    cursor:pointer; 
}
.notverified{
    color:red;
    font-family:cursive;
    font-weight:600;
    /* font-size:1.2em;  */
    cursor:pointer; 
}
@media only screen and (max-width: 800px) {
    .orderTable{
        justify-content: center;
        margin: 0% 0% 5% 0%;
        width:98%;
        max-width: 98%;
    }
    th,td{
        font-size: 12px;
        padding: 6px;
    }
}
