.orders{
    margin: 8% 0% 2% 20%;
    display: flex;
    color: black;
    width:80% ;
    position: relative;
    top:1%;
    z-index: 1;  
}
@media only screen and (max-width: 800px) {
    .orders{
        margin:15% 0% 6% 5%;
        width:100%;
    }
}